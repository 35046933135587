import { getToken, returnError } from './helperFunc';
import {
	transReportAction,
	summaryReportAction,
	epayReportAction,
	manualPayReportAction,
	getPaymentTypesAction,
	getDNGPaymentTypesAction,
	billReportAction,
	errorcodeReportAction,
	machineSalesTransReportAction,
	hourlySalesTransReportAction,
	salesByMachineTypeAction,
	systemErrorReportAction,
	systemErrorReportUpdateAction,
	dngReportAction
} from './requestEndpoints';
import { LOGOUT_SUCCESS } from './userReducer';

const SET_REPORT_LOADING = 'SET_REPORT_LOADING';
const CLEAR_REPORT_ERROR = 'CLEAR_REPORT_ERROR';

const TRANSACTION_REPORT_SUCCESS = 'TRANSACTION_REPORT_SUCCESS';
const TRANSACTION_REPORT_FAIL = 'TRANSACTION_REPORT_FAIL';

const MACHINE_SALES_TRANSACTION_REPORT_SUCCESS = 'MACHINE_SALES_TRANSACTION_REPORT_SUCCESS';
const MACHINE_SALES_TRANSACTION_REPORT_FAIL = 'MACHINE_SALES_TRANSACTION_REPORT_FAIL';

const HOURLY_SALES_TRANSACTION_REPORT_SUCCESS = 'HOURLY_SALES_TRANSACTION_REPORT_SUCCESS';
const HOURLY_SALES_TRANSACTION_REPORT_FAIL = 'HOURLY_SALES_TRANSACTION_REPORT_FAIL';

const SALES_SUMMARY_SUCCESS = 'SALES_SUMMARY_SUCCESS';
const SALES_SUMMARY_FAIL = 'SALES_SUMMARY_FAIL';

const EPAY_RECORD_SUCCESS = 'EPAY_RECORD_SUCCESS';
const EPAY_RECORD_FAIL = 'EPAY_RECORD_FAIL';

const DNG_RECORD_SUCCESS = 'DNG_RECORD_SUCCESS';
const DNG_RECORD_FAIL = 'DNG_RECORD_FAIL';

const BILL_RECORD_SUCCESS = 'BILL_RECORD_SUCCESS';
const BILL_RECORD_FAIL = 'BILL_RECORD_FAIL';

const MANUALPAY_RECORD_SUCCESS = 'MANUALPAY_RECORD_SUCCESS';
const MANUALPAY_RECORD_FAIL = 'MANUALPAY_RECORD_FAIL';

const ERROR_RECORD_SUCCESS = 'ERROR_RECORD_SUCCESS';
const ERROR_RECORD_FAIL = 'ERROR_RECORD_FAIL';

const SALES_BY_MACHINE_TYPE_SUCCESS = 'SALES_BY_MACHINE_TYPE_SUCCESS';
const SALES_BY_MACHINE_TYPE_FAIL = 'SALES_BY_MACHINE_TYPE_FAIL';

const SYSTEM_ERROR_REPORT_SUCCESS = 'SYSTEM_ERROR_REPORT_SUCCESS';
const SYSTEM_ERROR_REPORT_FAIL = 'SYSTEM_ERROR_REPORT_FAIL';

const SYSTEM_ERROR_REPORT_UPDATE_SUCCESS = 'SYSTEM_ERROR_REPORT_UPDATE_SUCCESS';
const SYSTEM_ERROR_REPORT_UPDATE_FAIL = 'SYSTEM_ERROR_REPORT_UPDATE_FAIL';

// FOR BROWSABLE REPORTS

// /api/report/sales/v1/epayment
// /api/report/sales/v1/manualpay

export const transReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await transReportAction(values, token);

		dispatch({ type: TRANSACTION_REPORT_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: TRANSACTION_REPORT_FAIL, payload: returnError(error) });
	}
};

export const summaryReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await summaryReportAction(values, token);

		dispatch({ type: SALES_SUMMARY_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: SALES_SUMMARY_FAIL, payload: returnError(error) });
	}
};

export const epayReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await epayReportAction(values, token);
		// console.log(data);
		dispatch({ type: EPAY_RECORD_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: EPAY_RECORD_FAIL, payload: returnError(error) });
	}
};

export const dngReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await dngReportAction(values, token);
		// console.log(data);
		dispatch({ type: DNG_RECORD_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: DNG_RECORD_FAIL, payload: returnError(error) });
	}
};

export const getBillReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await billReportAction(values, token);
		// console.log(data);
		dispatch({ type: BILL_RECORD_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: BILL_RECORD_FAIL, payload: returnError(error) });
	}
};

export const manualPayReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await manualPayReportAction(values, token);

		dispatch({ type: MANUALPAY_RECORD_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: MANUALPAY_RECORD_FAIL, payload: returnError(error) });
	}
};

export const errorcodeReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await errorcodeReportAction(values, token);

		dispatch({ type: ERROR_RECORD_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: ERROR_RECORD_FAIL, payload: returnError(error) });
	}
};

export const getPaymentTypes = () => async (dispatch, getState) => {
	try {
		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await getPaymentTypesAction(token);

		return data;
		// console.log(data);
	} catch (error) {
		return [];
	}
};

export const getDNGPaymentTypes = () => async (dispatch, getState) => {
	try {
		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await getDNGPaymentTypesAction(token);

		return data;
		// console.log(data);
	} catch (error) {
		return [];
	}
};

export const machineSalesTransReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await machineSalesTransReportAction(values, token);

		dispatch({
			type: MACHINE_SALES_TRANSACTION_REPORT_SUCCESS,
			payload: data,
		});
		// console.log(data);
	} catch (error) {
		dispatch({
			type: MACHINE_SALES_TRANSACTION_REPORT_FAIL,
			payload: returnError(error),
		});
	}
};

export const hourlySalesTransReport = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await hourlySalesTransReportAction(values, token);

		dispatch({
			type: HOURLY_SALES_TRANSACTION_REPORT_SUCCESS,
			payload: data,
		});
		// console.log(data);
	} catch (error) {
		dispatch({
			type: HOURLY_SALES_TRANSACTION_REPORT_FAIL,
			payload: returnError(error),
		});
	}
};

export const salesByMachineType = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await salesByMachineTypeAction(values, token);

		dispatch({ type: SALES_BY_MACHINE_TYPE_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: SALES_BY_MACHINE_TYPE_FAIL, payload: returnError(error) });
	}
};

export const systemErrorReportType = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await systemErrorReportAction(values, token);

		dispatch({ type: SYSTEM_ERROR_REPORT_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: SYSTEM_ERROR_REPORT_FAIL, payload: returnError(error) });
	}
};

export const systemErrorReportUpdate = (values) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_REPORT_ERROR });
		dispatch({ type: SET_REPORT_LOADING });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await systemErrorReportUpdateAction(values, token);

		dispatch({ type: SYSTEM_ERROR_REPORT_UPDATE_SUCCESS, payload: data });
		// console.log(data);
	} catch (error) {
		dispatch({ type: SYSTEM_ERROR_REPORT_UPDATE_FAIL, payload: returnError(error) });
	}
};

const initialState = {
	language: 'en',
	operator: null,
	error: null,
	loading: false,
	errorUpdated: null,
};

export const reportReducer = function (state = initialState, action) {
	switch (action.type) {
		// this is for fetching loading time setter
		case SET_REPORT_LOADING:
			return {
				...state,
				loading: true,
			};

		case CLEAR_REPORT_ERROR:
			return {
				...state,
				error: null,
			};

		case TRANSACTION_REPORT_SUCCESS:
			// console.log('action.payload', action.payload);
			return {
				report: action.payload.data,
				loading: false,
				error: null,
			};

		case MACHINE_SALES_TRANSACTION_REPORT_SUCCESS:
			return {
				machineSalesTransaction: action.payload,
				loading: false,
				error: null,
			};

		case HOURLY_SALES_TRANSACTION_REPORT_SUCCESS:
			return {
				hourlySalesTransaction: action.payload,
				loading: false,
				error: null,
			};

		case SALES_BY_MACHINE_TYPE_SUCCESS:
			// console.log('action.payload', action.payload);
			return {
				salesByMachineTypeData: action.payload.data,
				summary: action.payload.summary,
				loading: false,
				error: null,
			};

		case SALES_SUMMARY_SUCCESS:
			return {
				salesSummaryData: action.payload.data,
				loading: false,
				error: null,
			};

		case EPAY_RECORD_SUCCESS:
			return {
				epay: action.payload.data,
				loading: false,
				error: null,
			};

		case DNG_RECORD_SUCCESS:
			return {
				dng: action.payload.data,
				loading: false,
				error: null,
			};

		case BILL_RECORD_SUCCESS:
			return {
				billReport: action.payload.data,
				loading: false,
				error: null,
			};

		case MANUALPAY_RECORD_SUCCESS:
			return {
				manual: action.payload.data,
				loading: false,
				error: null,
			};

		case ERROR_RECORD_SUCCESS:
			return {
				errorReport: action.payload.data,
				loading: false,
				error: null,
			};

		case SYSTEM_ERROR_REPORT_SUCCESS:
			return {
				systemErrorReport: action.payload.data,
				loading: false,
				error: null,
			};
		case SYSTEM_ERROR_REPORT_UPDATE_SUCCESS:
			return {
				...state,
				errorUpdated: action.payload.data,
				loading: false,
				error: null,
			};

		case LOGOUT_SUCCESS:
			return initialState;

		case TRANSACTION_REPORT_FAIL:
		case SALES_SUMMARY_FAIL:
		case EPAY_RECORD_FAIL:
		case MANUALPAY_RECORD_FAIL:
		case ERROR_RECORD_FAIL:
		case MACHINE_SALES_TRANSACTION_REPORT_FAIL:
		case HOURLY_SALES_TRANSACTION_REPORT_FAIL:
		case SALES_BY_MACHINE_TYPE_FAIL:
		case SYSTEM_ERROR_REPORT_FAIL:
		case SYSTEM_ERROR_REPORT_UPDATE_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		default:
			return state;
	}
};
